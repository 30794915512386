import { lazy, Suspense } from 'react';

import LoadingScreen from '../common/loading-screen';

const Loadable = Component => props =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// General Components
export const NotFoundPage = Loadable(lazy(() => import('../common/404')));

// Guest Pages
export const LoginPage = Loadable(lazy(() => import('../pages/guest/Login')));
export const ForgotPasswordPage = Loadable(
  lazy(() => import('../pages/guest/ForgotPassword'))
);
export const OtpVerificationPage = Loadable(
  lazy(() => import('../pages/guest/OtpVerification'))
);
export const SuccessfullySentPage = Loadable(
  lazy(() => import('../pages/guest/SuccessfullySent'))
);

// Auth Pages
export const DashboardPage = Loadable(
  lazy(() => import('../pages/auth/Dashboard'))
);
export const PartnerListingPage = Loadable(
  lazy(() => import('../pages/auth/partner'))
);
export const PartnerCreatePage = Loadable(
  lazy(() => import('../pages/auth/partner/create'))
);
export const PartnerViewPage = Loadable(
  lazy(() => import('../pages/auth/partner/view'))
);
export const PartnerEditPage = Loadable(
  lazy(() => import('../pages/auth/partner/edit'))
);

export const GameNodeMappingListingPage = Loadable(
  lazy(() => import('../pages/auth/game-node-mapping/index'))
);
export const GameNodeMappingFormPage = Loadable(
  lazy(() => import('../pages/auth/game-node-mapping/form'))
);

export const UsersListingPage = Loadable(
  lazy(() => import('../pages/auth/users'))
);
export const UsersViewPage = Loadable(
  lazy(() => import('../pages/auth/users/form'))
);
export const UserFriendsPage = Loadable(
  lazy(() => import('../pages/auth/users/friends'))
);

export const PayoutsPage= Loadable(
  lazy(() => import('../pages/auth/affiliate-partners/payout'))
);
export const OnboardingPage = Loadable(
  lazy(() => import('../pages/auth/affiliate-partners/onboarding'))
);
export const PortalUsersListingPage = Loadable(
  lazy(() => import('../pages/auth/portal-users'))
);
export const PortalUsersCreatePage = Loadable(
  lazy(() => import('../pages/auth/portal-users/create'))
);
export const PortalUsersViewPage = Loadable(
  lazy(() => import('../pages/auth/portal-users/view'))
);
export const PortalUsersEditPage = Loadable(
  lazy(() => import('../pages/auth/portal-users/edit'))
);

export const RolesListingPage = Loadable(
  lazy(() => import('../pages/auth/roles'))
);
export const RolesCreatePage = Loadable(
  lazy(() => import('../pages/auth/roles/create'))
);
export const RolesViewPage = Loadable(
  lazy(() => import('../pages/auth/roles/view'))
);
export const RolesEditPage = Loadable(
  lazy(() => import('../pages/auth/roles/edit'))
);

export const VmsListingPage = Loadable(lazy(() => import('../pages/auth/vms')));
export const VMsForm = Loadable(
  lazy(() => import('../pages/auth/vms/form'))
);
export const VmsViewPage = Loadable(
  lazy(() => import('../pages/auth/vms/view'))
);
export const EventListingPage = Loadable(
  lazy(() => import('../pages/auth/events'))
);

export const NodesListingPage = Loadable(
  lazy(() => import('../pages/auth/nodes'))
);
export const NodeForm = Loadable(
  lazy(() => import('../pages/auth/nodes/form'))
);
export const NodesViewPage = Loadable(
  lazy(() => import('../pages/auth/nodes/view'))
);

export const LiveGameSessionsListingPage = Loadable(
  lazy(() => import('../pages/auth/live-game-sessions'))
);

export const SubscriptionPackageListingPage = Loadable(
  lazy(() => import('../pages/auth/subscription-packages'))
);
export const SubscriptionPackageCreatePage = Loadable(
  lazy(() => import('../pages/auth/subscription-packages/create'))
);
export const SubscriptionPackageEditPage = Loadable(
  lazy(() => import('../pages/auth/subscription-packages/edit'))
);
export const SubscriptionPackageViewPage = Loadable(
  lazy(() => import('../pages/auth/subscription-packages/view'))
);

export const UserSubscriptionListingPage = Loadable(
  lazy(() => import('../pages/auth/user-subscriptions'))
);
export const UserSubscriptionCreatePage = Loadable(
  lazy(() => import('../pages/auth/user-subscriptions/create'))
);
export const UserSubscriptionEditPage = Loadable(
  lazy(() => import('../pages/auth/user-subscriptions/edit'))
);

export const GamesListingPage = Loadable(
  lazy(() => import('../pages/auth/games'))
);
export const GameFormPage = Loadable(
  lazy(() => import('../pages/auth/games/form'))
);
export const GameViewPage = Loadable(
  lazy(() => import('../pages/auth/games/view'))
);

export const FeedsListingPage = Loadable(
  lazy(() => import('../pages/auth/feeds'))
);
export const FeedFormPage = Loadable(
  lazy(() => import('../pages/auth/feeds/form'))
);
export const FeedViewPage = Loadable(
  lazy(() => import('../pages/auth/feeds/view'))
);

export const StoresListingPage = Loadable(
  lazy(() => import('../pages/auth/stores'))
);
export const StoresCreatePage = Loadable(
  lazy(() => import('../pages/auth/stores/create'))
);
export const StoreEditPage = Loadable(
  lazy(() => import('../pages/auth/stores/edit'))
);
export const StoreViewPage = Loadable(
  lazy(() => import('../pages/auth/stores/view'))
);

export const GenresListingPage = Loadable(
  lazy(() => import('../pages/auth/genres'))
);
export const GenresCreatePage = Loadable(
  lazy(() => import('../pages/auth/genres/create'))
);
export const GenresEditPage = Loadable(
  lazy(() => import('../pages/auth/genres/edit'))
);
export const GenresViewPage = Loadable(
  lazy(() => import('../pages/auth/genres/view'))
);
export const DeveloperPublisherListingPage = Loadable(
  lazy(() => import('../pages/auth/developer-publisher'))
);
export const DeveloperPublisherFormPage = Loadable(
  lazy(() => import('../pages/auth/developer-publisher/form'))
);
export const DeveloperPublisherViewPage = Loadable(
  lazy(() => import('../pages/auth/developer-publisher/view'))
);

export const GameStatsPage = Loadable(
  lazy(() => import('../pages/auth/games/game-stats'))
);

export const CouponsListingPage = Loadable(
  lazy(() => import('../pages/auth/coupons'))
);
export const CouponForm = Loadable(
  lazy(() => import('../pages/auth/coupons/form'))
);
export const CouponsViewPage = Loadable(
  lazy(() => import('../pages/auth/coupons/view'))
);

export const UserActivityLogsListingPage = Loadable(
  lazy(() => import('../pages/auth/user-activity-logs'))
);

export const BannersListingPage = Loadable(
  lazy(() => import('../pages/auth/banners'))
);
export const BannersFormPage = Loadable(
  lazy(() => import('../pages/auth/banners/form'))
);
export const BannersViewPage = Loadable(
  lazy(() => import('../pages/auth/banners/view'))
);

export const NotificationsListingPage = Loadable(
  lazy(() => import('../pages/auth/notifications'))
);
export const NotificationForm = Loadable(
  lazy(() => import('../pages/auth/notifications/form'))
);
export const NotificationsViewPage = Loadable(
  lazy(() => import('../pages/auth/notifications/view'))
);