import { createTheme } from '@mui/material/styles';

const OneplayTheme = createTheme({
  palette: {
    background: {
      lightPurple: '#F5F1FF',
      linearGradient: 'linear-gradient(300.3deg, #0575E6 9.6%, #FF0CF5 92.85%)',
      customBg: '#151515',
      lightCustomBg: '#212123',
      redGreenGradient:
        'linear-gradient(300.3deg, #b2102f 9.6%, #357a38 92.85%)',
      green: '#357a38',
      red: '#b2102f',
      lightblack: '#0000006b',
      indigo: '#2a3eb1',
      teal: '#14a37f',
      transparent: 'transparent',
      bluecolor: '#0575E6',
      pinkbg: '#FF0CF5',
      lightPink: '#ff579a',
      lightBlue: '#5c60f5',
      skyblue: '#00d4ff',
      warningBg: '#ff8c00',
      lightGreen: '#4bac10d9',
      lightBrown: '#862c41e0',
      restrictedBg: '#fb0928c4',
      goldenBg: '#ebc72d',
      rainbowBg:
        'linear-gradient(300.3deg, red,orange,yellow,green,blue,indigo,violet)',
    },
    color: {
      black: '#000000',
      customColor: '#959595',
      white: '#ffffff',
    },
  },
});

export default OneplayTheme;
