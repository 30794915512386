import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  Badge as BadgeIcon,
  Campaign as CampaignIcon,
  Category as CategoryIcon,
  Dashboard as DashboardIcon,
  Dns as DnsIcon,
  DynamicFeed as DynamicFeedIcon,
  Event as EventIcon,
  Games as GamesIcon,
  Insights as GameStatsIcon,
  LiveTv as LiveTvIcon,
  People as PeopleIcon,
  ReceiptLong,
  Storage as StorageIcon,
  Storefront as StorefrontIcon,
  Subscriptions as SubscriptionsIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon
} from '@mui/icons-material';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
export const menu = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
    is_group: false,
    route: '/auth/dashboard',
    permission: 'Dashboard_Display',
    children: []
  },
  {
    id: 'game_management',
    title: 'Game Management',
    icon: <GamesIcon />,
    is_group: true,
    path_prefix: 'game-mgmt',
    permissions: ['Games_Display', 'Genres_Display', 'Store Management_Display', 'Publishers_Display', 'Game Statistic_Display'],
    children: [
      {
        id: 'game_management_games',
        title: 'Games',
        icon: <GamesIcon />,
        is_group: false,
        route: '/games',
        permission: 'Games_Display',
      },
      {
        id: 'game_management_genres',
        title: 'Genres',
        icon: <CategoryIcon />,
        is_group: false,
        route: '/genres',
        permission: 'Genres_Display',
      },
      {
        id: 'game_management_stores',
        title: 'Stores',
        icon: <StorefrontIcon />,
        is_group: false,
        route: '/stores',
        permission: 'Store Management_Display',
      },
      {
        id: 'game_management_publishers',
        title: 'Publishers',
        icon: <DashboardIcon />,
        is_group: false,
        route: '/publishers',
        permission: 'Publishers_Display',
      },
      {
        id: 'game_statistics',
        title: 'Game Statistics',
        icon: <GameStatsIcon />,
        is_group: false,
        route: '/game-stats',
        permission: 'Game Statistic_Display',
        children: []

      }
    ]
  },
  {
    id: 'feeds',
    title: 'Collections',
    icon: <DynamicFeedIcon />,
    is_group: false,
    route: '/auth/feeds',
    permission: 'Collections_Display',
    children: []
  },
  {
    id: 'user_management',
    title: 'User Management',
    icon: <PeopleIcon />,
    is_group: true,
    path_prefix: 'user-mgmt',
    permissions: ['User Management_Display', 'Events_Display', 'Live Game Sessions_Display'],
    children: [
      {
        id: 'user_management_users',
        title: 'Users',
        icon: <PeopleIcon />,
        is_group: false,
        route: '/users',
        permission: 'User Management_Display',
      },
      {
        id: 'user_management_events',
        title: 'Events',
        icon: <EventIcon />,
        is_group: false,
        route: '/events',
        permission: 'Events_Display',
      },
      {
        id: 'user_management_live_game',
        title: 'Live Game Sessions',
        icon: <LiveTvIcon />,
        is_group: false,
        route: '/live-game-sessions',
        permission: 'Live Game Sessions_Display',
      }
    ]
  },
  {
    id: 'subscription_management',
    title: 'Subscription Management',
    icon: <SubscriptionsIcon />,
    is_group: true,
    path_prefix: 'subscription-mgmt',
    permissions: ['Subscription Packages_Display', 'Coupons_Display'],
    children: [
      {
        id: 'plan-category',
        title: 'Category Management',
        icon: <CategoryIcon />,
        is_group: false,
        route: '/plan-categories',
        permission: 'Category Management_Display'
      },
      {
        id: 'subscription',
        title: 'Subscription Packages',
        icon: <SubscriptionsIcon />,
        is_group: false,
        route: '/subscription-packages',
        permission: 'Subscription Packages_Display'
      },
      {
        id: 'coupons',
        title: 'Coupons',
        icon: <EventIcon />,
        is_group: false,
        route: '/coupons',
        permission: 'Coupons_Display',
      }
    ]
  },
  {
    id: 'partner_management',
    title: 'Partner Management',
    icon: <SupervisedUserCircleIcon />,
    is_group: true,
    path_prefix: 'partner-mgmt',
    permissions: ['Partners_Display', 'Game Node Mapping_Display'],
    children: [
      {
        id: 'partner_management_partners',
        title: 'Partners',
        icon: <SupervisedUserCircleIcon />,
        is_group: false,
        route: '/partner',
        permission: 'Partners_Display',
      },
      {
        id: 'partner_management_game_node_mapping',
        title: 'Game Node Mapping',
        icon: <DashboardIcon />,
        is_group: false,
        route: '/game-node-mapping',
        permission: 'Game Node Mapping_Display',
      }
    ]
  },
  {
    id: 'banners',
    title: 'Banner Management',
    icon: <CampaignIcon />,
    is_group: false,
    route: '/auth/banner-mgmt',
    permission: 'Banner Management_Display',
    children: []
  },
  {
    id: 'notifications',
    title: 'Notifications Management',
    icon: <NotificationsPausedIcon />,
    is_group: false,
    route: '/auth/notifications',
    permission: 'Notifications Management_Display',
    children: []
  },
  {
    id: 'server_management',
    title: 'Server Management',
    icon: <DnsIcon />,
    is_group: true,
    path_prefix: 'server-mgmt',
    permissions: ['Nodes_Display', 'VMs_Display'],
    children: [
      {
        id: 'server_management_nodes',
        title: 'Nodes',
        icon: <DnsIcon />,
        is_group: false,
        route: '/nodes',
        permission: 'Nodes_Display',
      },
      {
        id: 'server_management_vms',
        title: 'VMs',
        icon: <StorageIcon />,
        is_group: false,
        route: '/vms',
        permission: 'VMs_Display',
      }
    ]
  },
  {
    id: 'administration',
    title: 'Administration',
    icon: <AdminPanelSettingsIcon />,
    is_group: true,
    path_prefix: 'administration',
    permissions: [
      'Portal Users_Display',
      'Roles_Display',
      'User Activity Log_Display',
      'Affiliate_Onboarding',
      'Affiliate_Payout',
    ],
    children: [
      {
        id: 'administration_portal_users',
        title: 'Portal Users',
        icon: <AdminPanelSettingsIcon />,
        is_group: false,
        route: '/portal-users',
        permission: 'Portal Users_Display',
      },
      {
        id: 'administration_roles',
        title: 'Roles',
        icon: <BadgeIcon />,
        is_group: false,
        route: '/roles',
        permission: 'Roles_Display',
      },
      {
        id: 'user_activity_logs',
        title: 'User Activity Logs',
        icon: <BookRoundedIcon />,
        is_group: false,
        route: '/user-activity-logs',
        permission: 'User Activity Log_Display',
      },
      {
        id: 'affiliate-partners',
        title: 'Affiliate Partners',
        icon: <AdminPanelSettingsIcon />,
        path_prefix: 'administration',
        is_group: true,
        permissions: [
          'Affiliate_Onboarding',
          'Affiliate_Payout',
        ],
        children: [
          {
            id: 'affiliate_payout',
            title: 'Payout/Reports',
            icon: <ReceiptLong />,
            is_group: false,
            route: '/payout',
            permission: 'Affiliate_Payout',
          },
          {
            id: 'affiliate_onboarding',
            title: 'Onboarding',
            icon: <PeopleIcon />,
            is_group: false,
            route: '/onboarding',
            permission: 'Affiliate_Onboarding',
          },
        ],
      },
    ]
  },
];