import Axios from './Axios';
const partner_id = localStorage.getItem('selected_partner');

const makeRequest = (url, params, obj = null) => {
  return new Promise((resolve) => {
    Axios.get(url, { params: params })
      .then(response => {
        if (response.success) {
          if (obj != null && typeof response.data[obj] != 'undefined') {
            resolve(response.data[obj]);
          } else {
            resolve(response.data);
          }
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.error('[MasterData] makeRequest(' + url + ') exception:', error);
        resolve([]);
      });
  });
};

export const getPartners = async (all = false) => { return await makeRequest('/getAllPartner', all ? {} : {partner_id: partner_id}, 'partners'); };

export const getPartnerGames = async (all = false) => { return await makeRequest('getAllLiveGames', all ? {} : {partner_id: partner_id}, 'live_games'); };
export const getLiveGames = async () => { return await makeRequest('getAllLiveGames', {}, 'live_games'); };

export const getGenres = async () => { return await makeRequest('getAllGenre', {}, 'genres'); };

export const getDevelopers = async () => { return await makeRequest('getAllDeveloper', {}, 'developers'); };

export const getPublishers = async () => { return await makeRequest('getAllPublisher', {}, 'publishers'); };

export const getStores = async (params = {}) => { return await makeRequest('getAllStore', params, 'stores'); };

export const getPlatforms = async () => { return await makeRequest('getAllPlatform', {}, 'platforms'); };
export const getNotificationPlatforms = async () => { return await makeRequest('getAllNotificationPlatforms', {}, 'platforms'); };
export const getAllNotificationPlatformVersions = async () => { return await makeRequest('getAllNotificationPlatformVersions', {}, 'versions'); };
export const getNotificationTemplates = async () => { return await makeRequest('getNotificationTamplates', {}, 'notification_templates'); };

export const getNodes = async (partner_id) => { return await makeRequest('nodes', { partner_id: partner_id, paginate: 'No'}, 'nodes'); };

export const getNodeRegions = async (partner_id) => { return await makeRequest('getAllNodeRegions', { partner_id: partner_id, paginate: 'No' }, 'node-regions'); };

export const getModules = async (partner_id) => { return await makeRequest('getAllModules', { partner_id: partner_id, paginate: 'No' }, 'modules'); };

export const getAllPortalUsers = async (partner_id) => { return await makeRequest('getAllPortalUsers', { partner_id: partner_id, paginate: 'No' }, 'users'); };
export const getSpecialLabels = async (all = false) => { return await makeRequest('/getSpecialLabels', all ? {} : { partner_id: partner_id }, 'special_labels'); };
export const getActiveSubscriptionPackages = async (partner_id) => { return await makeRequest('getAllActiveSubscriptionPackages', { partner_id: partner_id, paginate: 'No' }, 'subscription_packages'); };
