import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import PropsContext from '../../common/PropsContext';
import { get } from '../../utils/SecureLocalStorage';
import AppBarPartial from './app-bar';
import LeftDrawerPartial from './left-drawer';

export default function AuthLayout() {
  const [leftSidebarStatus, setLeftSidebarStatus] = React.useState(true);
  let location = useLocation();
  let token = get('token') !== undefined;

  let auth_user_details = get('user');
  let role_with_modules_permissions = auth_user_details?.role_with_modules_permissions || [];

  const handleDrawer = () => {
    setLeftSidebarStatus(!leftSidebarStatus);
  };

  return token ? (
    <Box sx={{ display: '-webkit-box' }} className="fontFamily">
      <CssBaseline />
      <AppBarPartial
        leftSidebarStatus={leftSidebarStatus}
        handleDrawer={handleDrawer}
      />
      <LeftDrawerPartial
        leftSidebarStatus={leftSidebarStatus}
        handleDrawer={handleDrawer}
      />
      <Box
        component="main"
        className="customBodyStyle"
        sx={{ flexGrow: 1, pl: 3, pr: 3, pt: 11 }}
      >
        <PropsContext.Provider value={role_with_modules_permissions}>
          <Outlet />
        </PropsContext.Provider>
      </Box>
    </Box>
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
}
