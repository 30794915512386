import { Outlet } from 'react-router-dom';

import { clearAll,get } from '../utils/SecureLocalStorage';

export default function GuestLayout() {
  let token = get('token') !== undefined;
  console.log('tokenPresent: ', token);
  if (!token) {
    clearAll();
  }
  // else {
  //   window.location.href = '/auth/dashboard';
  // }
  return <Outlet />;
}
