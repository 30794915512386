import './assets/scss/index.scss';

import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import OneplayApp from './OneplayApp';
import OneplayTheme from './OneplayTheme';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <React.Suspense>
      <ThemeProvider theme={OneplayTheme}>
        <OneplayApp />
      </ThemeProvider>
    </React.Suspense>
  </BrowserRouter>
);
