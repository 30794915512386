import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import PropsContext from '../../../common/PropsContext';
import Axios from '../../../utils/Axios';
let initialFormState = {
  list: '',
  label: '',
  type: 'all',
  partner_id: localStorage.getItem('selected_partner') || 'All',
};

const stateData = {
  data: [],
  partners: [],
  roles: [],
  columns: [],
  filterVisibilityStatus: true,
  deleteModalVisibilityStatus: false,
  serverError: {
    error: false,
    message: null,
  },
  formState: initialFormState,
};

// const exportSelectedColumns = [
//   'id', 'image', 'name', 'type', 'rawg_id'
// ];
export default function PlanCategoriesListingPage() {
  const role_with_modules_permissions = useContext(PropsContext);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  let page = parseInt(queryParams.get('page')) || 1;
  let list = queryParams.get('list') || '';

  const [state, setState] = React.useState(stateData);

  const handlePageChange = (event, value) => {
    let list_search = '';
    if (list != '') {
      list_search = '&list='+list;
    }
    navigate({
      pathname: '/auth/subscription-mgmt/plan-categories',
      search: '?page=' + parseInt(value) + list_search,
    });
    getAllData(value);
  };

  const searchCollapse = () => {
    setState(prevStyle => ({
      ...prevStyle,
      filterVisibilityStatus: !state.filterVisibilityStatus,
    }));
  };

  const handleClearSearch = () => {
    setState(prevStyle => ({
      ...prevStyle,
      formState: initialFormState,
    }));
  };


  // const rows = [
  //   createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  //   createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  //   createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  //   createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  //   createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
  // ];


  // function createData(
  //   name,
  //   calories,
  //   fat,
  //   carbs,
  //   protein,
  //   price,
  // ) {
  //   return {
  //     name,
  //     calories,
  //     fat,
  //     carbs,
  //     protein,
  //     price,
  //     history: [
  //       {
  //         date: '2020-01-05',
  //         customerId: '11091700',
  //         amount: 3,
  //       },
  //       {
  //         date: '2020-01-02',
  //         customerId: 'Anonymous',
  //         amount: 1,
  //       },
  //     ],
  //   };
  // }


  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset', paddingBottom: '20px' } }} >
          <TableCell className="removePaddig sticky">
            {row?.category_plan_types.length > 0 &&
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <ExpandLessIcon />: <ExpandMoreIcon />}
                </IconButton>
            }
          </TableCell>
          <TableCell className="removePaddig sticky">
            <Box px={2} pr={0}>

              {(1==1 || role_with_modules_permissions.includes(
                'Category Management_Detail View'
              )) && (
                <NavLink
                  to={'/auth/subscription-mgmt/plan-categories/view/' + row?.id}
                >
                  <IconButton size="small" className="lightMutedBg">
                    <VisibilityIcon />
                  </IconButton>
                </NavLink>
              )}
              {(1== 1 || role_with_modules_permissions.includes(
                'Category Management_Edit'
              )) && (
                <Box component="span" ml={2} mr={0}>
                  <NavLink
                    to={
                      '/auth/subscription-mgmt/plan-categories/edit/' + row?.id
                    }
                  >
                    <IconButton
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  </NavLink>
                </Box>
              )}
            </Box>
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.label}
          </TableCell>
          <TableCell align="left">{row?.partner.name}</TableCell>
          <TableCell align="left">{row?.created_at}</TableCell>
          <TableCell align="left">{row?.created_by}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead style={{ backgroundColor: '#f0f0f0' }}>
                    <TableRow>
                      <TableCell>Plan Type</TableCell>
                      <TableCell>Plan Title</TableCell>
                      <TableCell>Created On</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Updated On</TableCell>
                      <TableCell>Updated By</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.category_plan_types.map((category_plan_type) => (
                      <TableRow key={category_plan_type.id}>
                        <TableCell>
                          {category_plan_type.plan_type}
                        </TableCell>
                        <TableCell>{category_plan_type.plan_title}</TableCell>
                        <TableCell>{category_plan_type.created_at}</TableCell>
                        <TableCell>{category_plan_type.created_by}</TableCell>
                        <TableCell>{category_plan_type.updated_at}</TableCell>
                        <TableCell>{category_plan_type.updated_by}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const getAllData = (page = 1) => {
    let params = state.formState;
    params.page = page;
    params.partner_id = localStorage.getItem('selected_partner');

    Axios.get('plan-categories', {
      params: params,
    }).then(response => {
      if (response.success) {
        setState(prevStyle => ({
          ...prevStyle,
          data: response.data.planCategories,
          columns: response.data.columns
        }));
      } else {
        setState(prevStyle => ({
          ...prevStyle,
          serverError: {
            error: true,
            message: response.errors,
          },
        }));
      }
    });
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    const keyDownHandler = event => {

      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ call submit function here
        handleSubmit(event);
      }
    };
    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [state.formState.name, state.formState.type]);

  const handleFormChange = event => {
    if (event.target.name === 'status') {
      const {
        target: { value },
      } = event;
      setState(prevStyle => ({
        ...prevStyle,
        formState: {
          ...prevStyle.formState,
          [event.target.name]:
            typeof value === 'string' ? value.split(',') : value,
        },
      }));
    } else {
      setState(prevStyle => ({
        ...prevStyle,
        formState: {
          ...prevStyle.formState,
          [event.target.name]: event.target.value,
        },
      }));
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      navigate({
        pathname: '/auth/subscription-mgmt/plan-categories',
        search: '?page=1',
      });
      let formdata = state.formState;
      formdata.page = 1;
      let response = await Axios.get('plan-categories', {
        params: formdata,
      });
      if (response.success) {
        setState(prevStyle => ({
          ...prevStyle,
          data: response.data.planCategories,
        }));
      } else {
        setState(prevStyle => ({
          ...prevStyle,
          serverError: {
            error: true,
            message: response.message,
            errors: response.errors,
          },
        }));
      }
    } catch (error) {
      setState(prevStyle => ({
        ...prevStyle,
        serverError: {
          error: true,
          message: 'Server is down',
        },
      }));
    }
  };

  return (
    <>
      <Box mb={3}>
        <Box
          mb={2}
          fontSize="20px"
          fontWeight="700"
          className="themeGradientText"
        >
          Category Management
        </Box>
        <Box className="cardBoxShadow br-top12">
          <Box p={1} className="mutedBG br-top12">
            <Grid container>
              <Grid item xs className="alignSelfCenter">
                <Box fontSize="18px" fontWeight="600">
                  Search Filter
                </Box>
              </Grid>
              <Grid item xs="auto" onClick={searchCollapse}>
                <Button
                  size="small"
                  startIcon={<UnfoldMoreIcon />}
                  className="customGradientBtn"
                  style={{
                    justifyContent: 'flex-start',
                    maxWidth: '90px',
                    minWidth: '90px',
                  }}
                >
                  {state.filterVisibilityStatus ? 'Collapse' : 'Expand'}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Collapse
            in={state.filterVisibilityStatus}
            timeout="auto"
            unmountOnExit
          >
            <Box p={2}>
              {/* TextField Section start */}
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Box className="LabelText">Category Title</Box>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={handleFormChange}
                    value={state.formState.label}
                    name="label"
                  />
                </Grid>

                <Grid item xs={3}>
                  <Box className="LabelText">Category Type</Box>
                  <Select
                    fullWidth
                    size="small"
                    name="type" // Use the property name directly
                    onChange={handleFormChange}
                    value={state.formState.type}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="regular">Regular</MenuItem>
                    <MenuItem value="none">None</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box p={1}>
              <Grid container>
                <Grid item xs={6}>
                  {/* Export Button Start */}
                  {/* <IconButton size="small" className="lightPrimaryBg">
                                        <FileUploadIcon className="primaryColor"/>
                                    </IconButton> */}
                  {/* Field Chooser Button */}
                  {/* <Box component="span" ml={1}>
                                        <IconButton size="small" className="linearBG">
                                            <ListAltIcon className="whitecolor"/>
                                        </IconButton>
                                    </Box> */}
                  {/* Result Data found Start */}
                  <Box className="font700 customFontSize" component="span">
                    {state.data
                      ? state.data.total + ' records found'
                      : 'No record found'}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="right">
                    {/* Clear Button Start */}
                    <IconButton
                      size="small"
                      className="lightPrimaryBg"
                      onClick={handleClearSearch}
                    >
                      <CancelIcon className="primaryColor" />
                    </IconButton>
                    {/* Search Button Start */}
                    <Box component="span" ml={2}>
                      <Button
                        size="small"
                        startIcon={<SearchIcon />}
                        variant="contained"
                        className="customGradientBtn"
                        onClick={handleSubmit}
                      >
                        Search
                      </Button>
                    </Box>
                    <Box component="span" ml={2}>
                      {/* <ExportDialogComponent
                        url="plan-categories"
                        params={state.formState}
                        filename="Publishers"
                        columns={state.columns}
                        selected={exportSelectedColumns}
                      /> */}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </Box>
      </Box>
      {role_with_modules_permissions.includes('Category Management_Add') && (
        <Box textAlign="right" mt={2}>
          <NavLink to="/auth/subscription-mgmt/plan-categories/create">
            <Button
              size="small"
              startIcon={<AddIcon />}
              variant="contained"
              className="customGradientBtn"
            >
              Add Category - Type - Plan
            </Button>
          </NavLink>
        </Box>
      )}
      <Box mt={2} className="cardBoxShadow">
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                <TableCell>Action</TableCell>
                <TableCell>Category Title</TableCell>
                <TableCell>Partner</TableCell>
                <TableCell>Added On</TableCell>
                <TableCell>Added By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state?.data?.data && state.data.data.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
            {/* <TableBody>
              {rows.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody> */}
          </Table>
        </TableContainer>
        <Divider />
        <Box textAlign="right" p={1}>
          <Pagination
            count={state.data.last_page}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
}
