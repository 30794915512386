import CryptoJS from 'crypto-js';
import SecureStorage from 'secure-web-storage';

let SECRET_KEY = '@#one$&^play2022&%*';

const SecureLocalStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY);
    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  },
});

export const set = (key, value) => {
  try {
    let serializedState = JSON.stringify(value);
    SecureLocalStorage.setItem(key, serializedState);
  } catch (e) {
    console.log(e);
  }
};

export const get = key => {
  try {
    let serializedState = SecureLocalStorage.getItem(key);

    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const clearAll = () => {
  try {
    localStorage.clear();
  } catch (e) {
    console.log(e);
  }
};
