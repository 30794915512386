import { Navigate, useRoutes } from 'react-router-dom';

import AuthLayout from '../layouts/auth/index';
import GuestLayout from '../layouts/Guest';
import PlanCategoriesListingPage from '../pages/auth/plan-categories';
import PlanCategoriesForm from '../pages/auth/plan-categories/form';
// import NotificationForm from '../pages/auth/notifications/view';
import {
  BannersFormPage,
  BannersListingPage,
  BannersViewPage,
  CouponForm,
  CouponsListingPage,
  CouponsViewPage,
  DashboardPage,
  DeveloperPublisherFormPage,
  DeveloperPublisherListingPage,
  DeveloperPublisherViewPage,
  EventListingPage,
  FeedFormPage,
  FeedsListingPage,
  FeedViewPage,
  ForgotPasswordPage,
  GameFormPage,
  GameNodeMappingFormPage,
  GameNodeMappingListingPage,
  GamesListingPage,
  GameStatsPage,
  GameViewPage,
  GenresCreatePage,
  GenresEditPage,
  GenresListingPage,
  GenresViewPage,
  LiveGameSessionsListingPage,
  LoginPage,
  NodeForm,
  NodesListingPage,
  NodesViewPage,
  NotFoundPage,
  NotificationForm,
  NotificationsListingPage,
  NotificationsViewPage,
  OnboardingPage,
  OtpVerificationPage,
  PartnerCreatePage,
  PartnerEditPage,
  PartnerListingPage,
  PartnerViewPage,
  PayoutsPage,
  PortalUsersCreatePage,
  PortalUsersEditPage,
  PortalUsersListingPage,
  PortalUsersViewPage,
  RolesCreatePage,
  RolesEditPage,
  RolesListingPage,
  RolesViewPage,
  StoreEditPage,
  StoresCreatePage,
  StoresListingPage,
  StoreViewPage,
  SubscriptionPackageCreatePage,
  SubscriptionPackageEditPage,
  SubscriptionPackageListingPage,
  SubscriptionPackageViewPage,
  SuccessfullySentPage,
  UserActivityLogsListingPage,
  UserFriendsPage,
  UsersListingPage,
  UserSubscriptionCreatePage,
  UserSubscriptionEditPage,
  UserSubscriptionListingPage,
  UsersViewPage,
  VMsForm,
  VmsListingPage,
  VmsViewPage
} from './elements';

export default function Router() {
  return useRoutes([
    {
      element: <GuestLayout />,
      children: [
        { element: <LoginPage />, index: true },
        { path: 'forgot-password', element: <ForgotPasswordPage /> },
        { path: 'fotp-verification', element: <OtpVerificationPage /> },
        { path: 'otp-sent-successfully', element: <SuccessfullySentPage /> },
      ],
    },
    {
      path: 'auth',
      element: <AuthLayout />,
      children: [
        { element: <Navigate to="dashboard" replace />, index: true },
        { path: 'dashboard', element: <DashboardPage /> },

        // Game Management Menu - START
        {
          path: 'game-mgmt/games/create',
          element: <GameFormPage mode="Create" />,
        },
        {
          path: 'game-mgmt/games/edit/:game_id',
          element: <GameFormPage mode="Edit" />,
        },
        { path: 'game-mgmt/games/view/:game_id', element: <GameViewPage /> },
        { path: 'game-mgmt/games', element: <GamesListingPage /> },

        { path: 'game-mgmt/genres/create', element: <GenresCreatePage /> },
        {
          path: 'game-mgmt/genres/view/:genre_id',
          element: <GenresViewPage />,
        },
        {
          path: 'game-mgmt/genres/edit/:genre_id',
          element: <GenresEditPage />,
        },
        { path: 'game-mgmt/genres', element: <GenresListingPage /> },

        { path: 'game-mgmt/stores/create', element: <StoresCreatePage /> },
        { path: 'game-mgmt/stores/view/:store_id', element: <StoreViewPage /> },
        { path: 'game-mgmt/stores/edit/:store_id', element: <StoreEditPage /> },
        { path: 'game-mgmt/stores', element: <StoresListingPage /> },

        // Game Management Menu - START
        {
          path: 'game-mgmt/publishers/create',
          element: <DeveloperPublisherFormPage mode="Create" />,
        },

        {
          path: 'game-mgmt/publishers/edit/:developer_publisher_id',
          element: <DeveloperPublisherFormPage mode="Edit" />,
        },
        {
          path: 'game-mgmt/publishers/view/:developer_publisher_id',
          element: <DeveloperPublisherViewPage mode="View" />,
        },
        {
          path: 'game-mgmt/publishers',
          element: <DeveloperPublisherListingPage />,
        },

        { path: 'game-mgmt/game-stats', element: <GameStatsPage /> },

        // Game Management Menu - END

        { path: 'feeds/view/:feed_id', element: <FeedViewPage /> },
        { path: 'feeds/edit/:feed_id', element: <FeedFormPage mode="Edit" /> },
        { path: 'feeds/create', element: <FeedFormPage mode="Create" /> },
        { path: 'feeds', element: <FeedsListingPage /> },

        // User Management Menu - START
        { path: 'user-mgmt/users', element: <UsersListingPage /> },
        { path: 'user-mgmt/users/view/:user_id', element: <UsersViewPage /> },

        { path: 'user-mgmt/events', element: <EventListingPage /> },

        {
          path: 'user-mgmt/live-game-sessions',
          element: <LiveGameSessionsListingPage />,
        },
        {
          path: 'user-mgmt/users/friends/:user_id',
          element: <UserFriendsPage />,
        },
        // User Management Menu - END

        // Subscription Management Menu - START
        {
          path: 'subscription-mgmt/subscription-packages/view/:subscription_package_id',
          element: <SubscriptionPackageViewPage />,
        },
        {
          path: 'subscription-mgmt/subscription-packages/edit/:subscription_package_id',
          element: <SubscriptionPackageEditPage />,
        },
        {
          path: 'subscription-mgmt/subscription-packages',
          element: <SubscriptionPackageListingPage />,
        },
        {
          path: 'subscription-mgmt/subscription-packages/create',
          element: <SubscriptionPackageCreatePage />,
        },

        //coupon management
        {
          path: 'subscription-mgmt/coupons/view/:coupon_id',
          element: <CouponsViewPage mode="View" />,
        },
        {
          path: 'subscription-mgmt/coupons/edit/:coupon_id',
          element: <CouponForm mode="Edit" />,
        },
        {
          path: 'subscription-mgmt/coupons/create',
          element: <CouponForm mode="Create" />,
        },
        { path: 'subscription-mgmt/coupons', element: <CouponsListingPage /> },

        {
          path: 'user-subscriptions/:user_id',
          element: <UserSubscriptionListingPage />,
        },
        {
          path: 'user-subscriptions/create/:user_id',
          element: <UserSubscriptionCreatePage />,
        },
        {
          path: 'user-subscriptions/edit/:subscription_id/:user_id',
          element: <UserSubscriptionEditPage />,
        },
        {
          path: 'subscription-mgmt/plan-categories/create',
          element: <PlanCategoriesForm mode="Create" />,
        },

        {
          path: 'subscription-mgmt/plan-categories/edit/:plan_categories_id',
          element: <PlanCategoriesForm mode="Edit" />,
        },
        {
          path: 'subscription-mgmt/plan-categories/view/:plan_categories_id',
          element: <PlanCategoriesForm mode="View" />,
        },
        {
          path: 'subscription-mgmt/plan-categories',
          element: <PlanCategoriesListingPage />,
        },
        // Subscription Management Menu - END

        // Partner Management Menu - START
        {
          path: 'partner-mgmt/partner/view/:partner_id',
          element: <PartnerViewPage />,
        },
        {
          path: 'partner-mgmt/partner/edit/:partner_id',
          element: <PartnerEditPage />,
        },
        { path: 'partner-mgmt/partner/create', element: <PartnerCreatePage /> },
        { path: 'partner-mgmt/partner', element: <PartnerListingPage /> },

        {
          path: 'partner-mgmt/game-node-mapping',
          element: <GameNodeMappingListingPage />,
        },
        {
          path: 'partner-mgmt/game-node-mapping/create',
          element: <GameNodeMappingFormPage mode="Create" />,
        },
        {
          path: 'partner-mgmt/game-node-mapping/edit/:hash_id',
          element: <GameNodeMappingFormPage mode="Edit" />,
        },
        // Partner Management Menu - END

        // Banner Management Menu - START
        { path: 'banner-mgmt/view/:banner_id', element: <BannersViewPage /> },
        {
          path: 'banner-mgmt/edit/:banner_id',
          element: <BannersFormPage mode="Edit" />,
        },
        {
          path: 'banner-mgmt/create',
          element: <BannersFormPage mode="Create" />,
        },
        { path: 'banner-mgmt', element: <BannersListingPage /> },
        // Banner Management Menu - END
        //Notification - Start
        {
          path: 'notifications/view/:notification_id',
          element: <NotificationsViewPage mode="View" />,
        },
        {
          path: 'notifications/edit/:notification_id',
          element: <NotificationForm mode="Edit" />,
        },
        {
          path: 'notifications/create',
          element: <NotificationForm mode="Create" />,
        },
        { path: 'notifications', element: <NotificationsListingPage /> },

        //Notification - END

        // Server  Management Menu - START
        { path: 'server-mgmt/vms/view/:vm_id', element: <VmsViewPage /> },
        {
          path: 'server-mgmt/vms/edit/:vm_id',
          element: <VMsForm mode="Edit" />,
        },
        { path: 'server-mgmt/vms/create', element: <VMsForm mode="Create" /> },
        { path: 'server-mgmt/vms', element: <VmsListingPage /> },

        { path: 'server-mgmt/nodes/view/:node_id', element: <NodesViewPage /> },
        {
          path: 'server-mgmt/nodes/edit/:node_id',
          element: <NodeForm mode="Edit" />,
        },
        {
          path: 'server-mgmt/nodes/create',
          element: <NodeForm mode="Create" />,
        },
        { path: 'server-mgmt/nodes', element: <NodesListingPage /> },
        // Server  Management Menu - END

        // Administration Menu - START
        {
          path: 'administration/payout',
          element: <PayoutsPage />,
        },
        {
          path: 'administration/onboarding',
          element:<OnboardingPage />,
        },
        {
          path: 'administration/portal-users',
          element: <PortalUsersListingPage />,
        },
        {
          path: 'administration/portal-users/create',
          element: <PortalUsersCreatePage />,
        },
        {
          path: 'administration/portal-users/view/:portal_user_id',
          element: <PortalUsersViewPage />,
        },
        {
          path: 'administration/portal-users/edit/:portal_user_id',
          element: <PortalUsersEditPage />,
        },

        { path: 'administration/roles', element: <RolesListingPage /> },
        { path: 'administration/roles/create', element: <RolesCreatePage /> },
        {
          path: 'administration/roles/edit/:role_id',
          element: <RolesEditPage />,
        },
        {
          path: 'administration/roles/view/:role_id',
          element: <RolesViewPage />,
        },

        {
          path: 'administration/user-activity-logs',
          element: <UserActivityLogsListingPage />,
        },


        // Administration Menu - END
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);
}
