import axios, { AxiosError } from 'axios';

import constants from '../config/constants';
import { get } from './SecureLocalStorage';

const instance = axios.create({
  baseURL: constants.BASE_URL,
});

instance.interceptors.request.use(
  config => {
    const token = get('token');

    if (token !== undefined) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => response.data,
  error => {
    if (error instanceof AxiosError && error.response?.status === 401) {
      //   useAuthStore.setState({ signedInAs: undefined });
      window.location = '/';
    }
    return Promise.resolve(error.response.data);
  }
);

export default instance;
