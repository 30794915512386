import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { get } from '../../utils/SecureLocalStorage';
import { menu } from './menu-list';

const drawerWidth = 315;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function LeftDrawerPartial(props) {
  let auth_user_details = get('user');
  let role_with_modules_permissions =
    auth_user_details.role_with_modules_permissions;

  // console.log(role_with_modules_permissions);
  const SingleLevel = ({ item, pathPrefix, sx }) => {
    if (item.permission == '' || role_with_modules_permissions.includes(item.permission)) {
      return (
        <ListItem
          button
          component={NavLink}
          to={(pathPrefix ?? '') + item.route || '/404'}
          sx={{
            minHeight: 48,
            justifyContent: props.leftSidebarStatus
              ? 'initial'
              : 'center',
            px: 2.5,
            ...sx
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: props.leftSidebarStatus ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.title}
            sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
          />
        </ListItem>
      );
    }
    return;
  };

  const MultiLevel = ({ item }) => {
    const location = useLocation();
    let defaultState = false;
    let isGranted = true;
    if (item.is_group && item?.path_prefix) {
      defaultState = location.pathname.includes(item.path_prefix);
    }
    const { children } = item;
    const [open, setOpen] = useState(defaultState);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    if (item.permissions) {
      const allowed = role_with_modules_permissions.filter(element => item.permissions.indexOf(element) !== -1);
      if (allowed && allowed.length > 0) {
        isGranted = true;
      } else {
        isGranted = false;
      }
    }

    return (
      <React.Fragment>
        {isGranted && (
          <>
            <ListItem
              button
              onClick={handleClick}
              sx={{
                minHeight: 48,
                justifyContent: props.leftSidebarStatus
                  ? 'initial'
                  : 'center',
                px: 2.5,
              }}
              className={open ? 'active-link' : ''}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.leftSidebarStatus ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
              />
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {children.map((child, key) => (
                  <MenuItem key={key} item={child} pathPrefix={item?.path_prefix} sx={{ paddingLeft: '12%' }} />
                ))}
              </List>
            </Collapse>
          </>
        )}
      </React.Fragment>
    );
  };

  const hasChildren = (item) => {
    const { children } = item;

    if (children === undefined) {
      return false;
    }

    if (children.constructor !== Array) {
      return false;
    }

    if (children.length === 0) {
      return false;
    }

    return true;
  };

  const MenuItem = ({ item, pathPrefix, sx }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} pathPrefix={pathPrefix} sx={sx} />;
  };

  return (
    <Drawer variant="permanent" open={props.leftSidebarStatus}>
      <Box component="main" sx={{ mt: 8 }}>
        {props.leftSidebarStatus && (
          <Box pt={2} pl={3} fontWeight="bold" className="themeGradientText">
            GENERAL
          </Box>
        )}
        <List>
          <ListItem disablePadding sx={{ display: 'block' }}>
            {menu.map((item, key) => <MenuItem key={key} item={item} />)}
          </ListItem>
        </List>
        <Divider />
        <Box pt={2} pl={3} fontWeight="bold" className="themeGradientText">
            v 1.7.2
        </Box>


        {/* {props.leftSidebarStatus && (
          <Box pt={2} pl={3} fontWeight="bold" className="themeGradientText">
            GENERAL
          </Box>
        )}
        <List>
          <ListItem disablePadding sx={{ display: 'block' }}>
            {role_with_modules_permissions.includes('Dashboard_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/dashboard"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes('Partners_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/partner"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <SupervisedUserCircleIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Partners"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: props.leftSidebarStatus
                  ? 'initial'
                  : 'center',
                px: 2.5,
              }}
              component={NavLink}
              to="/auth/partner/node-mapping"
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.leftSidebarStatus ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SupervisedUserCircleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Partner Game Mapping"
                sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
              />
            </ListItemButton>
            {role_with_modules_permissions.includes(
              'User Management_Display'
            ) && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/users"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  primary="User Management"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes('Portal Users_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/portal-users"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Portal Users"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes('Roles_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/roles"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <BadgeIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Roles"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes('Events_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/events"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <EventIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Events"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes('Nodes_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/nodes"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <DnsIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Nodes"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes('VMs_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/vms"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <StorageIcon />
                </ListItemIcon>
                <ListItemText
                  primary="VMs"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes(
              'Live Game Sessions_Display'
            ) && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/live-game-sessions"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LiveTvIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Live Game Sessions"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes(
              'Subscription Packages_Display'
            ) && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/subscription-packages"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <SubscriptionsIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Subscription Packages"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes('Games_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/game-mgmt/games"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <GamesIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Games"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes('Genres_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/game-mgmt/genres"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <CategoryIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Genres"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes('Feeds_Display') && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/feeds"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <DynamicFeedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Feeds"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
            {role_with_modules_permissions.includes(
              'Store Management_Display'
            ) && (
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.leftSidebarStatus
                    ? 'initial'
                    : 'center',
                  px: 2.5,
                }}
                component={NavLink}
                to="/auth/stores"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.leftSidebarStatus ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <StorefrontIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Stores"
                  sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
                />
              </ListItemButton>
            )}
          </ListItem>
        </List>
        <Divider /> */}
        {/* {props.leftSidebarStatus && (
          <Box pt={2} pl={3} fontWeight="bold" className="themeGradientText">
            MANAGEMENT
          </Box>
        )} */}
        {/* <List>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: props.leftSidebarStatus ? 'initial' : 'center',
                px: 2.5,
              }}
              component={NavLink}
              to="/auth/error-log"
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.leftSidebarStatus ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ErrorIcon />
              </ListItemIcon>
              <ListItemText
                primary="Error Log"
                sx={{ opacity: props.leftSidebarStatus ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List> */}
      </Box>
    </Drawer>
  );
}
